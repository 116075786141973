
@use '~@angular/material' as mat;
@use 'sass:map';

@mixin app-list-heading-theme($theme) {

	$color-config: mat.get-color-config($theme);
	$typography-config: mat.get-typography-config($theme);
	

	app-list-heading {

		.content {

			&.normal {

				[title] {
					@include mat.typography-level($typography-config, 'subheading-2');
					font-weight: bold;
				}
				line-height: 24px;

			}

			&.items {
				[heading-item] {
					@include mat.typography-level($typography-config, 'body-1');
				}
			}
			
		}

	}

}