
@use '~@angular/material' as mat;
@use 'sass:map';

@mixin app-list-item-theme($theme) {

	$color-config: mat.get-color-config($theme);
	$typography-config: mat.get-typography-config($theme);
	
	$foreground-palette: map.get($color-config, 'foreground');

	app-list-item {

		[item-heading] {
			@include mat.typography-level($typography-config, 'subheading-2');
			font-weight: bold;
			line-height: 24px
		}

		[item-subheading] {
			@include mat.typography-level($typography-config, 'body-1');
			opacity: .74;
		}

		.list-item-base {
		
			.list-item-prefix, .list-item-prefix-fixed {
		
				&:after { // divider
					background: mat.get-color-from-palette($foreground-palette, 'divider')
				}
			}
		}

	}

}