html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.width-container {
	max-width: 532px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	box-sizing: border-box;

	&.scrollable {
		overflow-y: auto;
	}
}

mat-toolbar.mat-toolbar {
	.width-container {
		max-width: calc(532px - 32px);
  }
}

.mat-tab-nav-bar, .mat-tab-header {
  letter-spacing: 0.089em;
  text-transform: uppercase;

  .mat-tab-label-active {
    opacity: 1
  }
}

.mat-button-base {
  letter-spacing: 0.089em;
  text-transform: uppercase;
}


mat-form-field {

	width: 100%;

	&.mat-form-field-appearance-outline  {
		.mat-form-field-wrapper {
			margin: 0;
		}

		.mat-form-field-flex {
			margin-top: 0;

			.mat-form-field-infix {
				border-top: 0;
				padding: 10px 0;

				// .mat-select-value {
				// 	line-height: 16px;
				// 	font-size: 16px;
				// }
				
				&::after {
					margin-top: -2.5px;
				}
			}

			.mat-form-field-outline {
				top: 0
			}

		}
		.mat-select-arrow-wrapper {
			transform: none;
		}

		.mat-form-field-suffix {
			top: 0 !important;

			.text-suffix {
				line-height: 100%;
			}

			mat-icon {
				transform: translateY(0.3em);
			}
		}
	}
	
}

// Lightbox edit
.lightbox-back{
	background-image: none !important;

	&::after {
		content: "\e5c4";
		font-family: 'Material Icons Outlined';
		font-weight: normal;
		font-style: normal;
		font-size: 24px;
		line-height: 1;
		letter-spacing: normal;
		text-transform: none;
		display: inline-block;
		white-space: nowrap;
		word-wrap: normal;
		direction: ltr;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;

		height: 56px;
		line-height: 56px;
		width: 56px;
		text-align: center;
	}
}


@import '~@swimlane/ngx-datatable/themes/material.scss';
@import './app/themes/dc-base.theme.scss';


mat-bottom-sheet-container {
	padding: 0 !important;
}

.mat-snack-bar-container {
	&:not(.mat-snack-bar-top) {
		margin-bottom: 64px !important;
	}
}