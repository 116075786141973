
@use '~@angular/material' as mat;
@use 'sass:map';

@mixin app-department-select-theme($theme) {

	$color-config: mat.get-color-config($theme);
	$typography-config: mat.get-typography-config($theme);
	
	$foreground-palette: map.get($color-config, 'foreground');


	app-department-select {

		.placeholder-item {
			color: mat.get-color-from-palette($foreground-palette, 'hint-text')
		}

	}

}