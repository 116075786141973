@use '~@angular/material' as mat;


@include mat.core();

$default-typography: mat.define-typography-config();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;


// PRIMARY
$dc-primary-palette: (
  50: #E6EEF6,
  100: #C0D5EA,
  200: #97B9DC,
  300: #6D9CCD,
  400: #4D87C3,
  500: #2E72B8,
  600: #296AB1,
  700: #235FA8,
  800: #1D55A0,
  900: #124291,
  A100: #7BC1FF,
  A200: #3EA5FF,
  A400: #2499FF,
  A700: #1592FF,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);
// PRIMARY

// ACCENT
$dc-accent-palette: (
  50: #FEF7E1,
  100: #FCEBB4,
  200: #FADD82,
  300: #F8CF4F,
  400: #F7C52A,
  500: #F5BB04,
  600: #F4B503,
  700: #F2AC03,
  800: #F0A402,
  900: #EE9601,
  A100: #FFFEFE,
  A200: #FFDBC0,
  A400: #FFCDA7,
  A700: #FFC498,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
// ACCENT



$dc-green-palette: (
  50: #E7F8E7,
  100: #C4ECC2,
  200: #9CE09A,
  300: #74D471,
  400: #57CA52,
  500: #39C134,
  600: #33BB2F,
  700: #2CB327,
  800: #24AB21,
  900: #179E15,
  A100: #A7FF94,
  A200: #75FF57,
  A400: #60FF3D,
  A700: #54FF2E,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$dc-orange-palette: (
  50: #FEF7E0,
  100: #FCEBB3,
  200: #FADD80,
  300: #F8CF4D,
  400: #F7C526,
  500: #F5BB00,
  600: #F4B500,
  700: #F2AC00,
  800: #F0A400,
  900: #EE9600,
  A100: #FFFEFD,
  A200: #FFDBC0,
  A400: #FFCCA7,
  A700: #FFC497,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$dc-red-palette: (
  50: #FCE3E8,
  100: #F6B8C5,
  200: #F1899E,
  300: #EB5977,
  400: #E63659,
  500: #E2123C,
  600: #DF1036,
  700: #DA0D2E,
  800: #D60A27,
  900: #CF051A,
  A100: #FFAFC2,
  A200: #FF7293,
  A400: #FF5980,
  A700: #FF4974,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$white-palette: (
  500: #FFFFFF,
  contrast: (
    500: $dark-primary-text,
  )
);

$black-palette: (
  500: #000000,
  contrast: (
    500: $light-primary-text,
  )
);


$dc-primary: mat.define-palette($dc-primary-palette, 500, 300, 700);
$dc-accent: mat.define-palette($dc-accent-palette, 500, 300, 700);

$dc-green: mat.define-palette($dc-green-palette, 500, 300, 700);
$dc-orange: mat.define-palette($dc-orange-palette, 500, 300, 700);
$dc-red: mat.define-palette($dc-red-palette, 500, 300, 700);
$white: mat.define-palette($white-palette, 500, 500, 500);
$black: mat.define-palette($black-palette, 500, 500, 500);


// Foreground palette for light themes. --> Darker dividers
$light-theme-foreground-palette: (
  base:              black,
  divider:           rgba(black, 0.61),
  dividers:          rgba(black, 0.61),
  disabled:          rgba(black, 0.38),
  disabled-button:   rgba(black, 0.26),
  disabled-text:     rgba(black, 0.38),
  elevation:         black,
  hint-text:         rgba(black, 0.38),
  secondary-text:    rgba(black, 0.54),
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);



// The "warn" palette is optional and defaults to red if not specified.
$dc-warn: mat.define-palette(mat.$red-palette);

$dc-theme: mat.define-light-theme((
	color: (
		primary: $dc-primary,
		accent: $dc-accent,
		warn: $dc-warn
	),
	typography: $default-typography,
));

// Fiddling around to change the foreground palette:
$dc-color-config: map-get($dc-theme, color);
$dc-foreground: map-get($dc-color-config, foreground);
$dc-foreground: map-merge($dc-foreground, $light-theme-foreground-palette);
$dc-color-config: map-merge($dc-color-config, (foreground: $dc-foreground));
$dc-theme: map-merge($dc-theme, (color: $dc-color-config));

@include mat.all-component-themes($dc-theme);



@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import './component-themes';
@include app-custom-component-themes($dc-theme);


@import './layout-helpers';
@import './color-helpers';
@include app-color-classes((
	'primary': $dc-primary,
	'accent': $dc-accent,
	'dc-green': $dc-green,
	'dc-orange': $dc-orange,
	'dc-red': $dc-red,
  'white': $white,
  'black': $black
));


@import './company-themes';


// Annoying outline color overrides:
.mat-form-field-appearance-outline .mat-form-field-outline {
	color: map-get($light-theme-foreground-palette, 'divider');
}
.mat-form-field-label {
	color: map-get($light-theme-foreground-palette, 'divider');
}
