
@use '~@angular/material' as mat;
@use 'sass:map';

@mixin app-list-page-header-theme($theme) {

	$color-config: mat.get-color-config($theme);
	$typography-config: mat.get-typography-config($theme);
	

	app-list-page-header {

		.page-title {
			@include mat.typography-level($typography-config, 'title');
			line-height: 1.2em;
		}

		.page-filter {
			@include mat.typography-level($typography-config, 'input');
			font-size: 14px !important;
		}

		.page-subheading {
			@include mat.typography-level($typography-config, 'body-1');
		}

	}

}