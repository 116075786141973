
@use '~@angular/material' as mat;
@use 'sass:map';

@mixin app-navigation-theme($theme) {

	$color-config: mat.get-color-config($theme);
	
	$foreground-palette: map.get($color-config, 'foreground');
	$background-palette: map.get($color-config, 'background');
	$primary-palette:    map.get($color-config, 'primary');

	app-navigation {

		background-color: mat.get-color-from-palette($background-palette, 'card');

		.mat-nav-list {

			&::before {
				background-color: mat.get-color-from-palette($foreground-palette, 'dividers');
			}

			.nav-item {

				// default color, grey
				color: mat.get-color-from-palette($foreground-palette, 'text');
				mat-icon {
					color: inherit;
				}

				box-sizing: border-box;
				border-left: 0px solid currentColor;

				transition: background-color, .1s ease-out, color .1s ease-out, border .1s ease-out;

				&::before {
					transition: left .1s ease-out;
					background-color: mat.get-color-from-palette($foreground-palette, 'dividers');
				}

				.mat-list-item-content {
					transition: padding .1s ease-out;

					mat-icon {
						transition: color .1s ease-out;
					}
					.mat-line {
						font-weight: inherit;
					}
				}

				&.active {

					border-left: 4px solid currentColor;

					background-color: transparentize(mat.get-color-from-palette($primary-palette, 500), .92) !important;
					color: darken(mat.get-color-from-palette($primary-palette, 500), 10%);
					font-weight: 500;

					&::before {
						left: 29px !important;
					}

					.mat-list-item-content {
						padding-left: 12px;
					}

				}

			}
		}

	}

}