
@use '~@angular/material' as mat;
@use 'sass:map';

@mixin app-input-label-theme($theme) {

	$color-config: mat.get-color-config($theme);
	$typography-config: mat.get-typography-config($theme);
	
	$foreground-palette: map.get($color-config, 'foreground');

	app-input-label {
		@include mat.typography-level($typography-config, 'subheading-2');
		font-weight: bold;


		.secondary-text {
			@include mat.typography-level($typography-config, 'caption');

		}
	}

}